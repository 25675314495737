import DB from "@Cyclope/core/store/db";
import queryString from "query-string";

const fnInitApp = (dispatch) => {
    const dbRetrieve = DB.retrieve(":auth");
    dbRetrieve.then(response => {
        if (response && response.token && response.menu) {
            let search = queryString.parse(window.location.search);
            if (typeof search === "object" && Object.keys(search).length && search.model) {
                let data = {
                    model: `Project.${search.model}, Project, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null`,
                    action: search.action !== undefined ? search.action : "Show",
                    data: {}
                }
                delete search.model;
                delete search.action;

                data.data = search;
                dispatch.state.mainRedrawing(data);
                dispatch.setting.initAuth({})
            }
            dispatch.state.loginReceived(response).then(() => {
                if (response.initialRoute) dispatch.state.mainRedrawing(response.initialRoute);
                dispatch.setting.initAuth({})
            })
        }
    });
}
export default fnInitApp;