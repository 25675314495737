import React from "react";
import ReactDOM from "react-dom";
import App from "@Cyclope/App";
import * as serviceWorker from "./serviceWorker";
import {initializeIcons} from "@fluentui/react/lib/Icons"

initializeIcons();
ReactDOM.render(
    <App />,
    document.getElementById("root")
);

serviceWorker.unregister();
