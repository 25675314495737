import {findById, findProperty} from './extra/functions';
const _ = require('lodash');

const sendToRedraind = (dispatch, component, id, callback) => {
    dispatch.state.redrawing({
        action: component.update,
        id: id,
        callback: callback
    })
}

const fnUpdateParent = (dispatch, payload, storage) => {
    let data = Object.assign([], storage.data),
        key = findById(data, payload);

    if (key !== -1) {
        const components = data[key].data;
        let updateing = 0;

        Object.keys(components).forEach((keyUpdate) => {
            if (components[keyUpdate].update) {
                updateing++;
                sendToRedraind(dispatch, components[keyUpdate], payload, () => {
                    updateing--;
                    if (updateing === 0) {
                        dispatch.state.componentLoading({
                            id: payload,
                            type: false
                        })
                    }
                })
            } else if (data[key].action_tab) {
                let paths = findProperty(components[keyUpdate], data[key].action_tab);
                // Костыль #1
                let fixedPath = [];
                paths.forEach(element => {
                    let flag = true;
                    fixedPath.forEach(str => {
                        if (element.indexOf(str) !== -1) flag = false;
                    })
                    if (flag) fixedPath.push(element)
                });
                paths = fixedPath;

                if (paths.length) {
                    paths.forEach((val) => {
                        let elem = components[keyUpdate],
                            path = val.split('.');

                        for (let i=0, len=path.length; i<len; i++){
                            elem = elem[path[i]];
                        };

                        if (!_.isUndefined(elem[data[key].action_tab].update) && !_.isNull(elem[data[key].action_tab].update)) {
                            updateing++;

                            sendToRedraind(dispatch, elem[data[key].action_tab], payload, () => {
                                updateing--;
                                if (updateing === 0) {
                                    dispatch.state.componentLoading({
                                        id: payload,
                                        type: false
                                    })
                                }
                            })
                        }
                    })
                }
            } else {
                let paths = findProperty(components[keyUpdate], 'update');
                // Костыль
                let fixedPath = [];
                paths.forEach(element => {
                    let flag = true;
                    fixedPath.forEach(str => {
                        if (element.indexOf(str) !== -1) flag = false;
                    })
                    if (flag) fixedPath.push(element)
                });
                paths = fixedPath;

                if (paths.length) {
                    paths.forEach((val) => {
                        let elem = components[keyUpdate],
                            path = val.split('.');

                        for (let i = 0, len = path.length; i < len; i++){
                            elem = elem[path[i]];
                        }

                        if (!_.isUndefined(elem.update) && !_.isNull(elem.update)) {
                            updateing++;

                            sendToRedraind(dispatch, elem, payload, () => {
                                updateing--;
                                if (updateing === 0) {
                                    dispatch.state.componentLoading({
                                        id: payload,
                                        type: false
                                    })
                                }
                            })
                        }
                    })
                }
            }
        })

        if (updateing===0) {
            dispatch.state.componentLoading({
                id: payload,
                type: false
            })
        }
    } else {
        console.error('Объект для обновления с id = ' + payload + ' не найден')
    }
}
export default fnUpdateParent;
