const uuid = require('uuid/v4');

export const container = (pid, data, title = "") => {
    let obj = {}
    obj.guiType = "tabs";
    obj.id = uuid();
    obj.pid = pid;
    obj.title = title;
    obj.data = data;
    obj.loading = false;
    obj.disabled = false;
    obj.update = false;
    obj.action_tab = null;

    if (obj.title.trim() === "") {
        obj.title = "Пустой title";
    }

    return obj;
}

export const containerDraw = (pid, response, storageDrawer, title = "") => {
    let obj = Object.assign({}, storageDrawer);
    obj.guiType = "draw";
    obj.id = uuid();
    obj.pid = pid;
    obj.title = title;
    obj.data = response.draw;
    obj.visible = true;
    obj.width = response.width?response.width:1000;
    obj.loading = false;
    obj.disabled = false;
    obj.update = false;
    obj.action_tab = null;

    if (obj.title.trim() === "") {
        obj.title = "Пустой title";
    }

    return obj;
}
