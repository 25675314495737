import React, {Suspense} from "react";
import {connect} from 'react-redux';
import {isMobile} from 'react-device-detect';
import {Helmet} from "react-helmet";
import {ScreensProps} from "./index.d";

const _ = require('lodash'),
components = {
    desktop: {
        Login: React.lazy(() => import("@Cyclope/original/layouts/login")),
        Dashboard: React.lazy(() => import("@Cyclope/original/layouts/dashboard"))
    },
    mobile: {
        Login: React.lazy(() => import("./components/login")),
        Dashboard: React.lazy(() => import("./components/dashboard"))
    }
}

const Screens: React.FC<ScreensProps> = ({setting, token}) => {
    if (!setting.ready) return <div className="component-lazy">Загрузка...</div>;
    else {
        let screens: any = components.desktop;
        if (setting.isMobileDisplay) {
            screens = components.mobile;
        }
        return (
            <>
                <Helmet>
                    <title>{setting.appName}</title>
                </Helmet>
                <section id="screens" className={isMobile?"mobile":"desktop"}>
                    <Suspense fallback={<div className="component-lazy">Загрузка...</div>}>
                        {(!_.isNull(token) && !_.isEmpty(token) && !_.isUndefined(token))?<screens.Dashboard />:<screens.Login/>}
                    </Suspense>
                </section>
            </>
        )
    }
}

const mapState = (state: any) => {
    return {
        token: state.state.token,
        setting: state.setting
    };
};

export default connect(mapState)(Screens);
