import React from 'react';
import Core from '@Cyclope/core';
import {isMobile} from 'react-device-detect';

function App() {
    return (
        <div className="App" style={{width: isMobile? 1440: undefined}}>
            <Core />
        </div>
    );
}

export default App;
