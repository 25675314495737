import {findById} from './extra/functions';

const fnComponentLoading = (dispatch, payload, storage) => {
    const {id, type} = payload;
    let data = Object.assign([], storage.data),
        key = findById(data, id);

    if (key !== -1) {
        data[key].update = type;
        data[key].loading = type;

        dispatch.state.reloadData(data);
    } else console.error('Объект для обновления с id = ' + id + ' не найден');
}

const fnSetComponentLoading = (dispatch, payload, storage) => {
    const {id, flag} = payload;
    let data = Object.assign([], storage.data),
        key = findById(data, id);

    if (key !== -1) {
        data[key].loading = flag;

        dispatch.state.reloadData(data);
    } else console.error('Объект для обновления с id = ' + id + ' не найден');
}

export {
    fnComponentLoading,
    fnSetComponentLoading
}
