import React from "react";
import {message, notification} from "antd";
import DB from "@Cyclope/core/store/db";

const _ = require("lodash"),
axios = require("axios");

axios.defaults.headers.post["Accept"] = "application/json";
axios.defaults.headers.post["Content-Type"] = "application/json";

function printMessage(data, status) {
    let content = "Error code " + status;

    if (!_.isUndefined(data.errors)) {
        content = data.errors;
        if (_.isObject(data.errors)) {
            content = Object.keys(data.errors).map(function (key, index) {
                return <p key={index} className="mb-0">{data.errors[key]}</p>;
            });
        }
    } else if (typeof data === "string") {
        content = data;
    }

    notification.warning({
        message: "Oops...",
        description: content,
    });
}

export const post = (route, body, colback) => axios.post(route, JSON.stringify(body))
    .then(function (response) {
        if(colback && isJson(response.data)) {
            colback(JSON.parse(response.data), true);
        }
    })
    .catch(function (error) {
        if (error && error.response) {
            if (error.response.status===419 || error.response.status===401) {
                DB.destroy(":auth").then(() => {
                    window.location.reload();
                })
            } else if (error.response.status!==422) {
                message.error("Ошибка сервера №" + error.response.status);
            } else {
                printMessage(error.response.data, error.response.status)
            }
        }
        if (colback) colback(null, false);
    })
    .then(function () {
        // Всегда выполняется
    });

// Для запросов update для component
export const postGetData = (api, body, colback) => axios.post("/tapi/worker/getdata", JSON.stringify(body), {
    headers: { "Authorization": "Bearer " + api }
})
    .then(function (response) {
        if(colback && isJson(response.data)){
            colback(JSON.parse(response.data), true);
        }
    })
    .catch(function (error) {
        if (error && error.response) {
            if (error.response.status===419 || error.response.status===401) {
                DB.destroy(":auth").then(() => {
                    window.location.reload();
                })
            } else if (error.response.status!==422) {
                message.error("Ошибка сервера №" + error.response.status);
            } else {
                printMessage(error.response.data, error.response.status)
            }
        }
        if (colback) colback(null, false);
    })
    .then(function () {
        // Всегда выполняется
    });

export const postApi = (api, route, data, colback) => axios.post(route, data, {
    headers: { "Authorization": "Bearer " + api }
}).then(function (response) {
    if (colback && isJson(response.data)) {
        colback(JSON.parse(response.data), true);
    }
})
.catch(function (error) {
    if (error && error.response) {
        if (error.response.status===419 || error.response.status===401) {
            DB.destroy(":auth").then(() => {
                window.location.reload();
            })
        } else if (error.response.status!==422) {
            message.error("Ошибка сервера №" + error.response.status);
        } else {
            printMessage(error.response.data, error.response.status)
        }
    }
    if (colback) colback(null, false);
});

function isJson(str) {
    try {
        JSON.parse(str);
        return true;
    } catch(e) {}
    return false;
}
