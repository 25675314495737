import {findById} from './extra/functions';

const fnSetActiveTab = (dispatch, payload, storage) => {
    let data = Object.assign([], storage.data),
        key = findById(data, payload.id);

    if (key !== -1) {
        data[key].action_tab = payload.key;

        dispatch.state.reloadData(data);
    } else {
        console.error('Объект для обновления с id = ' + payload.id + ' не найден');
    }
}
export default fnSetActiveTab;
