export interface IStoreSetting {
    appName: string,
    showForgotPassword: boolean,
    isMobileDisplay: boolean,
    appTemplate: EnumAppTemplate,
    exitButtonTemplate: ExitButtonTemplate,
    logoHidden: boolean,
    logoPath: string | null,
    ready: boolean,
    [index: string]: any
}

export enum EnumAppTemplate {
    Sider = "Sider",
    TitleBar = "TitleBar"
}

export enum ExitButtonTemplate {
    Short = "Short",
    Text = "Text"
}
